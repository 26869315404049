<template>
  <div class="vid">
    <div class="title">
      {{ vdata.title }}
    </div>
    <div class="video" ref="video"></div>
    <div class="button">
      <img
        src="~@/assets/4.png"
        style="position: absolute;top:4vw;left:4vw;width:14vw"
        @click="home"
      />
      <img
      v-if="!vdata?.没有上一节"
        src="~@/assets/video-s.png"
        style="position: absolute;top:110vw;left:10vw;width:37.3vw;height:10.7vw"
        @click="syj"
      />
      <img
        v-if="id != lastid"
        src="~@/assets/video-x.png"
        style="position: absolute;top:110vw;right:10vw;width:37.3vw;height:10.7vw"
        @click="xyj"
      />
      <img
        v-else
        src="~@/assets/5.png"
        style="position: absolute;top:110vw;right:10vw;width:37.3vw;height:10.7vw"
        @click="totest"
      />
    </div>
  </div>
</template>

<script>
import DPlayer from "dplayer";
import videoData from "@/data/video";

export default {
  data: () => ({
    dp: null,
    vdata: {},
    lastid: 999,
  }),
  mounted() {
    const videoDom = this.$refs.video;
    const id = this.$route.query?.id;
    const vdata = videoData[id];
    this.id = id;
    this.vdata = vdata;
    this.lastid = videoData.length - 1;
    console.log(this.vdata, this.lastid);
    this.dp = new DPlayer({
      container: videoDom,
      video: {
        url: vdata.url,
      },
    });
  },
  methods: {
    syj() {
      let id = this.$route.query?.id;
      let menu = this.$route.query?.menu;
      this.$router.push({ path: "/video", query: { id: --id, menu: menu } });
    },
    xyj() {
      let id = this.$route.query?.id;
      let menu = this.$route.query?.menu;
      this.$router.push({ path: "/video", query: { id: ++id, menu: menu } });
    },
    home() {
      let menu = this.$route.query?.menu;
      this.$router.push({ path: "/menu", query: { type: menu } });
    },
    totest() {
      let menu = this.$route.query?.menu;
      if (2 != menu) return alert("主管没有测试题哦！");
      this.$router.push({ path: "/test", query: { menu: menu, id: 0 } });
    },
  },
};
</script>

<style scoped>
.vid {
  height: 100%;
  width: 100%;
  background-color: #f2f0eb;
  background-image: url("~@/assets/video-bg.jpg");
  background-color: #153d33;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% auto;
}

.title {
  padding-top: 15vw;
  font-size: 5.6vw;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
}

.video {
  margin-top: 13vw;
  background: #000;
  width: 100%;
  height: 62.3vw;
}
</style>
