export default [
    {
        title: '测试视频',
        url: 'https://fy-course-video.oss-cn-hangzhou.aliyuncs.com/H1280720/0031c90c1666f64a939f5c3823b18b031854.mp4',
    },
    /* 老板 */
    {
        title: '经理功能界面介绍',
        url: 'https://fy-course-video.oss-cn-hangzhou.aliyuncs.com/starbucks/%E7%BB%8F%E7%90%86%E5%8A%9F%E8%83%BD%E7%95%8C%E9%9D%A2%E4%BB%8B%E7%BB%8D.mp4',
        没有上一节: true,
    },
    {
        title: '设置培训模式',
        url: 'https://fy-course-video.oss-cn-hangzhou.aliyuncs.com/starbucks/%E8%AE%BE%E7%BD%AE%E5%9F%B9%E8%AE%AD%E6%A8%A1%E5%BC%8F.mp4',
    },
    {
        title: '设置员工卡及伙伴借调',
        url: 'https://fy-course-video.oss-cn-hangzhou.aliyuncs.com/starbucks/%E8%AE%BE%E7%BD%AE%E6%94%B6%E9%93%B6%E5%8D%A1%E5%8F%8A%E4%BC%99%E4%BC%B4%E5%80%9F%E8%B0%83.mp4',
    },
    {
        title: '现金管理-找零金分配',
        url: 'https://fy-course-video.oss-cn-hangzhou.aliyuncs.com/starbucks/%E7%8E%B0%E9%87%91%E7%AE%A1%E7%90%86-%E6%89%BE%E9%9B%B6%E9%87%91%E5%88%86%E9%85%8D.mp4',
    },

    /* 员工 */
    // 交互点单
    {
        title: '点单操作',
        url: 'https://fy-course-video.oss-cn-hangzhou.aliyuncs.com/starbucks/%E7%82%B9%E5%8D%95%E6%93%8D%E4%BD%9C.mp4',
        没有上一节: true,
    },
    {
        title: '使用好礼操作',
        url: 'https://fy-course-video.oss-cn-hangzhou.aliyuncs.com/starbucks/%E4%BD%BF%E7%94%A8%E5%A5%BD%E7%A4%BC%E6%93%8D%E4%BD%9C.mp4',
    },
    // 退货
    {
        title: '退货',
        url: 'https://fy-course-video.oss-cn-hangzhou.aliyuncs.com/starbucks/%E9%80%80%E8%B4%A7.mp4',
    },
    // 报损，试吃，伙伴饮料
    {
        title: '报损，试吃，伙伴饮料',
        url: 'https://fy-course-video.oss-cn-hangzhou.aliyuncs.com/starbucks/5%20%E6%8A%A5%E6%8D%9F%E8%AF%95%E5%90%83%E4%BC%99%E4%BC%B4%E9%A5%AE%E6%96%99.mp4',
    },
    // 会员卡、星礼卡SVC
    {
        title: '会员卡的查询和使用',
        url: 'https://fy-course-video.oss-cn-hangzhou.aliyuncs.com/starbucks/%E4%BC%9A%E5%91%98%E5%8D%A1%E7%9A%84%E6%9F%A5%E8%AF%A2%E5%92%8C%E4%BD%BF%E7%94%A8.mp4',
    },
    {
        title: '星礼包SR Kit售卖激活绑定及退卡',
        url: 'https://fy-course-video.oss-cn-hangzhou.aliyuncs.com/starbucks/%E6%98%9F%E7%A4%BC%E5%8C%85SR%20KIT%E5%94%AE%E5%8D%96%E6%BF%80%E6%B4%BB%E7%BB%91%E5%AE%9A%E5%8F%8A%E9%80%80%E5%8D%A1.mp4',
    },
    {
        title: 'SVC的查询',
        url: 'https://fy-course-video.oss-cn-hangzhou.aliyuncs.com/starbucks/SVC%E7%9A%84%E6%9F%A5%E8%AF%A2.mp4',
    },
    {
        title: 'SVC的激活及退卡',
        url: 'https://fy-course-video.oss-cn-hangzhou.aliyuncs.com/starbucks/SVC%E6%BF%80%E6%B4%BB%E5%8F%8A%E9%80%80%E5%8D%A1.mp4',
    },
    {
        title: 'SVC的充值及取消充值',
        url: 'https://fy-course-video.oss-cn-hangzhou.aliyuncs.com/starbucks/SVC%E7%9A%84%E5%85%85%E5%80%BC%E5%8F%8A%E5%8F%96%E6%B6%88%E5%85%85%E5%80%BC.mp4',
    },
    // 券类及B2B券售卖及兑换
    {
        title: '券类使用',
        url: 'https://fy-course-video.oss-cn-hangzhou.aliyuncs.com/starbucks/%E5%88%B8%E7%B1%BB%E4%BD%BF%E7%94%A8.mp4',
    },
    {
        title: '伙伴折扣',
        url: 'https://fy-course-video.oss-cn-hangzhou.aliyuncs.com/starbucks/%E4%BC%99%E4%BC%B4%E6%8A%98%E6%89%A3.mp4',
    },
    {
        title: '诚意券',
        url: 'https://fy-course-video.oss-cn-hangzhou.aliyuncs.com/starbucks/%E8%AF%9A%E6%84%8F%E5%88%B8.mp4',
    },
    // 报表和卡券功能介绍
    {
        title: '报表和卡券功能介绍',
        url: 'https://fy-course-video.oss-cn-hangzhou.aliyuncs.com/starbucks/%E6%8A%A5%E8%A1%A8%E5%92%8C%E5%8D%A1%E5%88%B8%E5%8A%9F%E8%83%BD%E4%BB%8B%E7%BB%8D.mp4',
    },
    // 点钞及关闭钱箱
    {
        title: '点钞及关闭钱箱',
        url: 'https://fy-course-video.oss-cn-hangzhou.aliyuncs.com/starbucks/%E7%82%B9%E9%92%9E%E5%8F%8A%E5%85%B3%E9%97%AD%E9%92%B1%E7%AE%B1.mp4',
    },
]